

  export default {
    props: { ctas: Array, CssClass: String },
    methods:
        {
            link: function(cta)
            {
                if (!cta){
                    return;
                }
                if (cta.internal.value == "true" && cta.scroll_to_id)
                {
                    return '#' + cta.scroll_to_id; 
                }
                return cta.internal == "true" && cta.linkpage.length != 0 ? '/' + cta.linkpage[0] : cta.url;
            }
        }
  };
